<script>
  import localizer from "@/common/i18n.js";
  import Button from "~/components/Button.svelte";
  import Form from "@/components/Form.svelte";
  import LoginBox from "@/components/LoginBox.svelte";
  import UsernameInput from "../../components/UsernameInput.svelte";
  import TurnstileProtected from "~/components/TurnstileProtected.svelte";

  export let sendCodeUrl;
  export let filteredParams = {};
  export let codeConfirmedUrl;
  export let changeRecipientUrl;
  export let username = '';
  export let turnstileKey = "";

  const t = localizer({
    nb: {
        header: 'Bruk passordfri innlogging',
        text: 'Velkommen! Nå trenger vi mobilnummeret ditt for å finne din aID. Hvis du ikke har en aID, så oppretter vi en for deg. I neste steg vil du motta en engangskode (gratis).',
        username: 'Mobilnummer',
        button: 'Neste'
    },
    nn: {
        header: 'Bruk passordfri innlogging',
        text: 'Velkomen! No treng vi mobilnummeret ditt for å finne din aID. Viss du ikkje har ein aID, så opprettar vi ein for deg. I neste steg vil du motta ein eingongskode (gratis).',
        username: 'Mobilnummer',
        button: 'Neste'
    }
  })
</script>

<LoginBox title="{t('header')}">
    <p>{t('text')}</p>
    <Form action="{sendCodeUrl}">
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}
        <input type="hidden" name="code_confirmed_url" value="{codeConfirmedUrl}" />
        <input type="hidden" name="change_recipient_url" value="{changeRecipientUrl}" />
        <input type="hidden" name="create_user_if_nonexistant" value="true" />

        <div>
            <UsernameInput label="{t('username')}" {username} />

            <TurnstileProtected {turnstileKey} action="webauthn_registration" let:completed let:requiresInteraction>
                <Button delayUntilTrue={completed} {requiresInteraction} prio="true" logName="start_webauthn_registration" type="submit">{t('button')}</Button>
            </TurnstileProtected>
        </div>
        </Form>
</LoginBox>

<style>
    div {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
</style>
