<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import FlashMessage from "../../components/FlashMessage.svelte";
    import Form from "../../components/Form.svelte";
    import Button from "../../components/Button.svelte";
    import {capitalize} from "../../common/utils.js";

    export let vendor;
    export let recipient;
    export let code;
    export let changeRecipientUrl;
    export let filteredParams;

    const t = localizer({
        nb: {
            header: `Aktiver {{vendor}}-innlogging`,
            info: `Takk! Trykk på knappen under for å aktivere {{vendor}}-innlogging på din aID: <b>{{recipient}}</b>`,
            button: `Aktiver {{vendor}}-innlogging`,
            notice: 'Skrev du feil mobilnummer?',
            changeRecipientLink: 'Gå tilbake og prøv igjen.',
        },
        nn: {
            header: `Aktiver {{vendor}}-innlogging`,
            info: `Takk! Trykk på knappen under for å aktivere {{vendor}}-innlogging på din aID: <b>{{recipient}}</b>`,
            button: 'Aktiver {{vendor}}-innlogging',
            notice: 'Skreiv du feil mobilnummer?',
            changeRecipientLink: 'Gå tilbake og prøv igjen.',
        }
    });

</script>

<LoginHeader title={t('header', {vendor: capitalize(vendor)})} />

<FlashMessage />

<p>
    {@html t('info', {vendor: capitalize(vendor), recipient})}
</p>

<Form action="/aid/logg_inn/lagre_{vendor}_kobling">
    {#each Object.entries(filteredParams) as [key, value]}
        <input type="hidden" name="{key}" value="{value}" />
    {/each}
    <input type="hidden" name="code" value={code} />
    <div>
        <Button logName="external_log_in" prio={true}>{t('button', {vendor: capitalize(vendor)})}</Button>
    </div>
</Form>

<p>
    {t('notice')}
    <a href={changeRecipientUrl}>{t('changeRecipientLink')}</a>
</p>

<style>
    div, p {
        margin-top: 18px;
    }
</style>