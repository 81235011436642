<script>
    import TurnstileInput from "~/components/TurnstileInput.svelte";

    export let turnstileKey = "";
    export let action = "unknown"
    let completed = false;
    let requiresInteraction = false;
</script>

<section>
    <TurnstileInput {turnstileKey} {action} on:completed={() => {completed = true}} on:interactionRequired={() => {requiresInteraction = true}} />
    <slot {completed} {requiresInteraction} />
</section>

<style>

</style>