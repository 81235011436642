/* global customElements, HTMLElement, FormData, document, window, XMLHttpRequest */
import Cookies from 'js-cookie';
import { AIDElement } from '../../common/element';
import createSVGElement from '../../lib/svg';

const texts = {
    success: {
        title: 'Velkommen',
        aid: {
            info1: 'Du bruker nå',
            info2: 'sitt nettverk, og har derfor tilgang til over 80 Amedia-publikasjoner.',
        },
        publication: {
            info1: 'Du har tilgang til',
            info2: 'gjennom',
        },
    },
    denied: {
        title: 'Beklager',
        ip: {
            info: 'Vi kan desverre ikke se at du er på et nettverk som gir tilgang til Amedias publikasjoner.',
            address: 'Din IP-adresse er ',
            wrong_network: 'Er du på feil nettverk? Bytt til riktig nettverk, og ',
            reload: 'last denne siden på nytt.',
            contact_support:
                'Hvis du vet at du er på riktig nettverk og fortsatt ikke får tilgang, kontakt support/kundestøtte eller nettverksadministrator for din bedrift.',
        },
        code: {
            info: 'Vi kunne ikke gi deg tilgang til',
            wrong_code: 'Noe er galt med lenken du brukte. Hvis du har klippet og limt, sjekk at du fikk med hele lenken. Hvis du har fulgt en lenke fra en skoleside e.l., ta kontakt med lærer.',
            contact_support: 'Er du lærer/administrator?  Kontakt Mediekompasset ved spørsmål.',
        }
    },
    system_error: 'Det skjedde en feil i våre systemer. Prøv igjen senere.',
};

class AidNonUserVstokenLoader extends AIDElement {
    connectedCallback() {
        this.isRendered();
    }

    createElementWithText = (elementType, text) => {
        const e = document.createElement(elementType);
        const t = document.createTextNode(text);
        e.appendChild(t);
        return e;
    };

    renderAccess(userName, customer, publicationDomain) {
        const logo = document.createElement('img');
        logo.classList.add('company-logo');
        logo.setAttribute('src', `/aid/tilgang/logo/${customer.logo}`);
        logo.setAttribute('alt', '');
        this.appendChild(logo);

        const header = this.createElementWithText('h2', texts.success.title + (userName !== '' ? `, ${userName}` : ``) + '!');
        this.appendChild(header);

        const infoBox = ( publicationDomain === 'www.aid.no'
                ? this.createInfoBox(`${texts.success.aid.info1} ${customer.name} ${texts.success.aid.info2}`, 'success')
                : this.createInfoBox(`${texts.success.publication.info1} ${this.getAttribute('publication_name')} ${texts.success.publication.info2} ${customer.name} `, 'success')
        );
        this.appendChild(infoBox);

        const escapedButtonMarkup = this.getAttribute('action_button');
        const buttonDOM = new DOMParser().parseFromString(escapedButtonMarkup, 'text/html');
        this.insertAdjacentHTML('beforeend', buttonDOM.documentElement.textContent);
    }

    renderAccessOrForward(userName, customer, publicationDomain) {
        if (this.getAttribute('goto_frontpage_on_access') === 'true') {
            window.location.href = '/';
        } else {
            this.renderAccess(userName, customer, publicationDomain);
        }
    }

    renderNoAccessByIP() {
        this.appendChild(this.createElementWithText('h2', texts.denied.title));
        const infobox = this.createInfoBox(texts.denied.ip.info, 'err');
        this.appendChild(infobox);

        const changeNetworkParagraph = this.createElementWithText('p', texts.denied.ip.wrong_network);
        changeNetworkParagraph.classList.add('margin');
        const reloadLink = this.createElementWithText('a', texts.denied.ip.reload);
        reloadLink.href = '';
        reloadLink.onclick = () => window.location.reload();
        changeNetworkParagraph.appendChild(reloadLink);
        this.appendChild(changeNetworkParagraph);

        const ipText = this.createElementWithText('small', texts.denied.ip.address);
        const ipAddress = this.createElementWithText('strong', this.getAttribute('ip_address'));
        ipText.appendChild(ipAddress);
        ipText.classList.add('ip-text');
        this.appendChild(ipText);

        this.appendChild(this.createElementWithText('small', texts.denied.ip.contact_support));
    }

    renderNoAccessByCode() {
        this.appendChild(this.createElementWithText('h2', texts.denied.title));
        const infobox = this.createInfoBox(`${texts.denied.code.info} ${this.getAttribute('publication_name')}.`, 'err');
        this.appendChild(infobox);

        const brokenLinkParagraph = this.createElementWithText('p', texts.denied.code.wrong_code);
        brokenLinkParagraph.classList.add('margin');
        this.appendChild(brokenLinkParagraph);

        this.appendChild(this.createElementWithText('small', texts.denied.code.contact_support));
    }

    svgCheck = () => {
        const wrapper = document.createElement('div');
        const svg = createSVGElement('svg', { viewBox: '0 0 120 120' });
        const path = createSVGElement('path', { d: 'M10,66 L60,100 L110,10' });
        svg.appendChild(path);
        wrapper.classList.add('svg-wrapper');
        wrapper.appendChild(svg);
        return wrapper;
    };

    svgError = () => {
        const wrapper = document.createElement('div');
        const svg = createSVGElement('svg', { viewBox: '0 0 120 120' });
        const pathA = createSVGElement('path', { d: 'M10,10 L110,110' });
        const pathB = createSVGElement('path', { d: 'M10,110 L110,10' });
        svg.appendChild(pathA);
        svg.appendChild(pathB);
        wrapper.classList.add('svg-wrapper');
        wrapper.appendChild(svg);
        return wrapper;
    };

    createInfoBox = (text, theme) => {
        const box = document.createElement('div');
        box.classList.add('info-box', theme);
        box.textContent = text;
        if (theme === 'success') {
            box.insertAdjacentElement('afterbegin', this.svgCheck());
        } else {
            box.insertAdjacentElement('afterbegin', this.svgError());
        }
        return box;
    };

    childrenParsedCallback() {
        const customers = {
            aid_test_inc: {
                name: 'aID-Testers Inc.',
                logo: 'aid_testing_inc.svg',
            },
            linpro: {
                name: 'Redpill Linpro',
                logo: 'redpilllinpro.svg',
            },
            conocophillips: {
                name: 'ConocoPhillips',
                logo: 'conocophillips.png',
            },
            equinor_offshore: {
                name: 'Equinor',
                logo: 'equinor-logo.svg',
            },
            neptune_energy: {
                name: 'Neptune Energy',
                logo: 'neptune_energy.png',
            },
            wintershall_dea: {
                name: 'Wintershall Dea',
                logo: 'wintershall_dea.svg',
            },
            avisuke44: {
                name: 'Nasjonal avisuke 44',
                logo: 'mediekompasset.png',
            },
            avisuke6og7_21: {
                name: 'Vis med Avis',
                logo: 'mediekompasset.png',
            },
            dittvalg2023: {
                name: 'Ditt valg 2023',
                logo: 'dittvalg2023_logo.svg',
            }
        };
        const userName = this.getAttribute('user_name');
        const code = this.getAttribute('code');
        const publicationDomain = this.getAttribute('publication_domain');

        // No user has access to www.aid.no, so we need to check access on some other domain if the user is on www.aid.no:
        const accessPublicationDomain = publicationDomain === 'www.aid.no' ? 'www.sb.no' : publicationDomain;
        const jupiterUrl = `/api/jupiter/v1/non_user_access/${accessPublicationDomain}`;

        const req = new XMLHttpRequest();
        req.open('post', jupiterUrl);
        req.withCredentials = true;
        req.onload = () => {
            if (req.status >= 200 && req.status < 300 || (code !== '' && req.status == 400)) {
                const response = JSON.parse(req.responseText);

                if (this.getAttribute('set_cookie') == 'true') {
                    Cookies.withConverter({
                        write: function(value, name) {
                            return value; // Don't encode value when setting cookie in browser.
                        }
                    }).set('VSTOKEN2', response.vstoken_cookie, { expires: 1 });
                }

                const customer = customers[response.uuid];
                if (customer != null) {
                    this.renderAccessOrForward(userName, customer, publicationDomain);
                } else if (code != '') {
                    this.renderNoAccessByCode();
                } else {
                    this.renderNoAccessByIP();
                }
            } else {
                this.error(texts.system_error);
            }
        };
        req.onerror = () => this.error(texts.system_error);
        const formData = new FormData();
        if (code != null) {
            formData.append('code', code);
        }
        return req.send(formData);
    }
}

if (!customElements.get('aid-non-user-vstoken-loader')) {
    customElements.define('aid-non-user-vstoken-loader', AidNonUserVstokenLoader);
}
