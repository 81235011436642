<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "@/components/LoginHeader.svelte";
    import FlashMessage from "@/components/FlashMessage.svelte";
    import Form from "@/components/Form.svelte";
    import TextInput from "@/components/TextInput.svelte";
    import BirthDateInput from "@/components/BirthDateInput.svelte";
    import Button from "@/components/Button.svelte";
    import UserTermsCheckbox from "@/components/UserTermsCheckbox.svelte";
    import { setContext } from "svelte";
    import PrivacyPolicyLink from "@/components/PrivacyPolicyLink.svelte";
    import PhoneInput from "../../components/PhoneInput.svelte";
    import TurnstileProtected from "~/components/TurnstileProtected.svelte";

    export let phone = '';
    export let name = '';
    export let email = '';
    export let birthDate;
    export let acceptTerms;
    export let terms;
    export let privacyPolicy;
    export let filteredParams = {};
    export let loginUrl;
    export let externalLoginProviders = [];
    export let turnstileKey = "";

    setContext('externalLoginProviders', externalLoginProviders)

    const t = localizer({
        nb: {
            header: 'Lag en aID',
            allFields: 'Alle felter må fylles ut.',
            phone: 'Mobilnummer',
            name: 'Fornavn og etternavn',
            email: 'E-post',
            birthDate: 'Fødselsdato',
            continue: 'Fortsett',
            or: 'Eller',
            login: 'logg inn med annet mobilnummer',
        },
        nn: {
            header: 'Lag ein aID',
            allFields: 'Alle felt må fyllast ut.',
            phone: 'Mobilnummer',
            name: 'Fornavn og etternavn',
            email: 'E-post',
            birthDate: 'Fødselsdato',
            continue: 'Fortsett',
            or: 'Eller',
            login: 'logg inn med anna mobilnummer',
        }
    })

</script>

<LoginHeader  title={t('header')} />

<div class="wrapper">
    <FlashMessage />

    <p class="all-fields">{t('allFields')}</p>

    <Form action="/aid/logg_inn/lagre_aid">
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}

        <div class="form">
            <div class="text-inputs">
                <PhoneInput name="phone" label={t('phone')} value={phone} required={true} />

                <TextInput name="name" label={t('name')} value={name} autocomplete="name" required={true} />

                <TextInput name="email" label={t('email')} value={email} type="email" autocomplete="email" maxLength={50} required={true}/>
            </div>

            <BirthDateInput label={t('birthDate')} day={birthDate?.mday} month={birthDate?.month} year={birthDate?.year} />

            <UserTermsCheckbox checked={acceptTerms} {terms} />

            <div class="privacy-policy">
                <PrivacyPolicyLink {privacyPolicy} />
            </div>

            <TurnstileProtected {turnstileKey} action="create_aid" let:completed let:requiresInteraction>
                <Button delayUntilTrue={completed} {requiresInteraction} prio={true} logName="create_aid">{t('continue')}</Button>
            </TurnstileProtected>
        </div>
    </Form>

    <p class="login-link">
        {t('or')}
        <a href={loginUrl}>
            {t('login')}
        </a>
    </p>
</div>

<style>
    .wrapper {
        margin-top: -8px;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    .all-fields {
        margin-bottom: -4px;
    }

    .wrapper {
        gap: 20px;
    }

    .form {
        margin-top: 15px;
        gap: 23px;
    }

    .text-inputs {
        gap: 35px;
        margin-bottom: 5px;
    }

    .privacy-policy {
        margin-top: 5px;
        margin-bottom: -8px;
    }

    .login-link {
        display: block;
        margin-top: 19px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 15px;
    }
</style>
