<script>
    import {Turnstile} from "svelte-turnstile";
    import FormInput from "~/components/FormInput.svelte";
    import { createEventDispatcher } from "svelte";
    
    export let turnstileKey = ""
    export let action = "unknown"
    export let token = ""

    let dispatch = createEventDispatcher();

    let hidden = true
</script>

{#if turnstileKey !== "" }
    <div class:hidden={hidden}>
        <FormInput name="captcha" let:errors>
            <Turnstile siteKey={turnstileKey}
                       size="flexible"
                       language="nb-no"
                       theme="light"
                       appearance={errors ? "always" : "interaction-only"}
                       on:before-interactive={() => {
                           hidden = false
                           dispatch("interactionRequired")
                       }}
                       on:callback={(result) => {
                           token = result.token;
                           dispatch("completed")
                       }}
                       on:error={({error}) => {console.error("Turnstile error", error)}}
                       {action}/>
        </FormInput>
    </div>
{/if}

<style>
    div.hidden {
        height: 0 !important;
    }

    div:not(.hidden) {
        padding-bottom: 20px;
    }
</style>