<script>
  import iconFingerprint from "@/images/icons/fingerprint-square.svg?raw";
  import iconPassPhoto from "@/images/icons/pass-photo.svg?raw";
  import InlineSVG from "@/components/InlineSVG.svelte";
  import Button from "~/components/Button.svelte";
  import localizer from "@/common/i18n.js";
  import ExpandableBox from "@/components/ExpandableBox.svelte";
  import {doNotPrioritizeWebauthn} from "~/common/webAuthn.js";
  import WebauthnLogo from "~/components/webauthn/WebauthnLogo.svelte";
  import FlashMessage from "~/components/FlashMessage.svelte";
  import {performLoginCeremony} from "~/common/webAuthn.js";
  import WebauthnRegistration from "@/components/webauthn/WebauthnRegistration.svelte";
  import WebauthnRegistrationComplete from "~/pages/login/WebauthnRegistrationComplete.svelte";
  import LoginBox from "@/components/LoginBox.svelte";

  export let legacyLoginUrl;
  export let webauthnStartRegistrationUrl;
  export let continueUrl = '/aid/';
  export let enterPasswordUrl = '/aid/logg_inn/velg_passord?request_password_change=true';
  export let sudoMode = false;

  function retryWebauthnLogin() {
    performLoginCeremony(window.location.href);
  }

  let webauthnRegistrationComplete = false

  function onRegistrationComplete() {
    webauthnRegistrationComplete = true;
  }

  const t = localizer({
    nb: {
      header: 'Passordfritt - enkelt og trygt',
      flash_retry: 'Prøv igjen',
      safe: 'Passordfri innlogging er tryggere enn passord, og tar bare få sekunder å sette opp.',
      devices: 'Med din mobil eller PC kan du opprette en passnøkkel for aid.no. Etter oppsett kan du bruke fingeravtrykk, ansiktsgjenkjenning eller lignende for å logge inn.',
      how: 'Slik fungerer det',
      click_continue: 'Trykk på “{{continue}}”',
      login: 'Logg inn (du vil få mulighet til å opprette ny aID hvis du ikke har en fra før)',
      instructions: 'Følg instruksjonene for å opprette en passnøkkel for aid.no',
      storing: 'Passnøkkelen lagres trygt på din mobil eller PC, eventuelt også i iCloud/Google Cloud eller lignende dersom du bruker dette.',
      using: 'Når du skal bruke passnøkkelen, vil din mobil eller PC be deg om ansiktsskanning, fingeravtrykk, PIN-kode eller lignende avhengig av hva du har tatt i bruk. Du trenger da ikke å taste mobilnummer eller passord for å logge inn i aID.',
      biometrics: 'Ditt fingeravtrykk eller din ansiktsskanning sendes aldri til aID (informasjonen forlater aldri din enhet).',
      continue: 'Ta i bruk passordfri innlogging',
      register_passkey: 'Start registrering av passnøkkel',
      password_instead: 'Bruk passord i stedet',
      read_more: 'Les mer om passordfri innlogging',
    },
    nn: {
      header: 'Passordfritt - enkelt og trygt',
      flash_retry: 'Prøv igjen',
      safe: 'Passordfri innlogging er tryggare enn passord, og tek berre få sekund å sette opp.',
      devices: 'Med mobilen eller PC-en din kan du opprette ein passnøkkel for aid.no. Etter oppsett kan du bruke fingeravtrykk, ansiktsgjenkjenning eller liknande for å logga inn.',
      how: 'Slik fungerer det',
      click_continue: 'Trykk på “{{continue}}”',
      login: 'Logg inn (du vil få høve til å opprette ny brukar viss du ikkje har ein frå før)',
      instructions: 'Følg instruksjonane for å opprette ein passnøkkel for aid.no',
      storing: 'Passnøkkelen blir lagra trygt på mobilen eller PC-en din, eventuelt også i iCloud/Google Cloud eller liknande dersom du bruker dette.',
      using: 'Når du skal bruke passnøkkelen, vil mobilen eller PC-en din be deg om ansiktsskanning, fingeravtrykk, PIN-kode eller liknande avhengig av kva du har teke i bruk. Du treng då ikkje å taste mobilnummer eller passord for å logga inn i aID.',
      biometrics: 'Fingeravtrykket ditt eller ansiktsskanninga di blir aldri send til aID (informasjonen forlet aldri eininga di).',
      continue: 'Fortsett',
      register_passkey: 'Start registrering av passnøkkel',
      password_instead: 'Bruk passord i staden',
      read_more: 'Les meir om passordfri innlogging',
    },
  })
</script>

{#if !webauthnRegistrationComplete}
  <LoginBox title="{t('header')}">
    <FlashMessage cta="{t('flash_retry')}" on:cta={retryWebauthnLogin} />
    <div class="info">
      <WebauthnLogo />
      <p>{t('safe')}</p>
    </div>
    <p class="devices">{t('devices')}</p>
    <ExpandableBox headerText={t('how')}>
      <div class="how">
        <ol>
          <li>{t('click_continue', {continue: (sudoMode ? t('register_passkey') : t('continue'))})}</li>
          <li>{t('login')}</li>
          <li>{t('instructions')}</li>
        </ol>
        <p>{t('storing')}</p>
        <p>{t('using')}</p>
      </div>
      <div class="biometrics">
        <p>{t('biometrics')}</p>
        <InlineSVG svg={iconFingerprint} --svg-square-size="32px" />
        <InlineSVG svg={iconPassPhoto} --svg-square-size="32px" />
      </div>
    </ExpandableBox>

    {#if sudoMode}
      <WebauthnRegistration prio={true} on:complete={onRegistrationComplete} {continueUrl}>{t('register_passkey')}</WebauthnRegistration>
      <a href={enterPasswordUrl} on:click={doNotPrioritizeWebauthn}>{t('password_instead')}</a>
    {:else}
      <Button href={webauthnStartRegistrationUrl} prio>{t('continue')}</Button>
      <a href={legacyLoginUrl} on:click={doNotPrioritizeWebauthn}>{t('password_instead')}</a>
    {/if}

    <a href="https://fidoalliance.org/" target="_blank" rel="noreferrer">{t('read_more')}</a>
  </LoginBox>
{:else}
  <WebauthnRegistrationComplete {continueUrl} />
{/if}

<style>
    .info {
        display: flex;
        gap: 1.3rem;
        --svg-square-size: 67px
    }
    .info p {
        flex: 1;
        font-size: 1rem;
        font-weight: 700;
        padding-right: 20px;
    }
    .devices {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.1;
        margin-left: 5px;
        margin-right: 20px;
    }
    .how ol {
        margin: 20px 0;
        padding-top: 0;
        padding-left: 0;
    }
    .how ol li {
        margin-left: 20px;
        margin-bottom: 0;
    }
    .biometrics {
        display: flex;
        gap: 12px;
        align-items: flex-start;
        font-weight: 700;
        font-size: 12px;
    }
    .biometrics p {
        color: rgba(0, 0, 0, .74);
        padding-right: 25px;
    }
    :global(.biometrics svg) {
        width: 32px;
    }
</style>
