<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import FlashMessage from "../../components/FlashMessage.svelte";
    import Form from "../../components/Form.svelte";
    import Button from "../../components/Button.svelte";
    import {capitalize} from "../../common/utils.js";
    import UsernameInput from "../../components/UsernameInput.svelte";
    import TurnstileProtected from "~/components/TurnstileProtected.svelte";

    export let vendor;
    export let name;
    export let sendCodeUrl;
    export let faqUrl;
    export let filteredParams;
    export let codeConfirmedUrl;
    export let changeRecipientUrl;
    export let externalAccountName;
    export let externalAccountEmail;
    export let turnstileKey = "";

    const t = localizer({
        nb: {
            header: `Koble {{vendor}} til en aID`,
            info: 'Hei, {{name}}! Nå trenger vi mobilnummeret ditt for å finne din aID. Hvis du ikke har en aID, så oppretter vi en for deg. I neste steg vil du motta en engangskode (gratis).',
            username: 'Mobilnummer:',
            button: 'Fortsett',
            notice: 'Hvorfor trenger dere mobilnummeret mitt?',
        },
        nn: {
            header: `Koble {{vendor}} til ein aID`,
            info: 'Hei, {{name}}! No treng vi mobilnummeret ditt for å finne din aID. Viss du ikkje har ein aID, så opprettar vi ein for deg. I neste steg vil du motta ein eingongskode (gratis).',
            username: 'Mobilnummer:',
            button: 'Fortsett',
            notice: 'Kvifor treng de mobilnummeret mitt?',
        }
    });
</script>

<LoginHeader title={t('header', {vendor: capitalize(vendor)})} />

<FlashMessage />

<p>{t('info', {name: name})}</p>

<Form action={sendCodeUrl}>
    {#each Object.entries(filteredParams) as [key, value]}
        <input type="hidden" name="{key}" value="{value}" />
    {/each}
    <input type="hidden" name="code_confirmed_url" value="{codeConfirmedUrl}" />
    <input type="hidden" name="change_recipient_url" value="{changeRecipientUrl}" />
    <input type="hidden" name="name" value="{externalAccountName}" />
    <input type="hidden" name="email" value="{externalAccountEmail}" />
    <input type="hidden" name="create_user_if_nonexistant" value="true" />

    <div>
        <UsernameInput label="{t('username')}" />

        <TurnstileProtected {turnstileKey} action="connect_external_to_aid" let:completed let:requiresInteraction>
            <Button delayUntilTrue={completed} {requiresInteraction} prio="true" logName="external_connect" type="submit">{t('button')}</Button>
        </TurnstileProtected>
    </div>

    <p>
        <a href={faqUrl}>{t('notice')}</a>
    </p>
</Form>

<style>
    div {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
</style>
